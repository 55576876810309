import { CARD_COMPANY_DETECTORS } from "./creditCardMethods";
import { MetricSender } from "../../../../lib/codeMetrics/MetricSender";
// So maybe you're wondering why these are separate and not combined into a single regex that
// would allow for detection of something like [\.\s-]? to cover them all. We are expecting
// that the same separator is used throughout the number, so we can't just combine them all.
const FOUR_GROUP_PATTERN_SPACES = "(\\d{4} \\d{4} \\d{4} \\d{4})";
const FOUR_GROUP_PATTERN_DASHES = "(\\d{4}-\\d{4}-\\d{4}-\\d{4})";
const FOUR_GROUP_PATTERN_DOTS = "(\\d{4}[.]\\d{4}[.]\\d{4}[.]\\d{4})";
const AMEX_DC_PATTERN_SPACES = "(\\d{4} \\d{5,6} \\d{5})";
const AMEX_DC_PATTERN_DASHES = "(\\d{4}-\\d{5,6}-\\d{5})";
const AMEX_DC_PATTERN_DOTS = "(\\d{4}[.]\\d{5,6}[.]\\d{5})";

const NO_SPACES_PATTERN = "(\\d{14,16})";

const LAX_NUMBER_REGEX = new RegExp(
  `${NO_SPACES_PATTERN}|${FOUR_GROUP_PATTERN_SPACES}|${FOUR_GROUP_PATTERN_DASHES}|${FOUR_GROUP_PATTERN_DOTS}|${AMEX_DC_PATTERN_SPACES}|${AMEX_DC_PATTERN_DASHES}|${AMEX_DC_PATTERN_DOTS}`,
);

const UUID_REGEX =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;
const INTL_PHONE_NUMBER_REGEX = /\+[1-4]{1,2} ?[0-9]+/g;
const UPS_REGEX = /1Z[0-9A-Z]{16}/g;
const COORDINATES_REGEX =
  /-?(90|[0-8]?\d)(\.\d+), -?(180|1[0-7]\d|\d?\d)(\.\d+)/g;
const IMEI_REGEX = /IMEI:? *\d{15}/g;
const URI_REGEX = /(?:[a-zA-Z]+):\/\/(?:[^\s/$.?#].[^\s]*)/g;

export function isPciCompliant(source: string | undefined): boolean {
  if (!source) return true;

  const filtered = removeFalsePositivePatterns(source);
  if (!filtered) return true;

  if (!cardFound(filtered)) return true;

  void new MetricSender({
    enabled: true,
    repoName: "JobberOnline",
  }).sendBatch([{ metric: "pci_compliance.detection.hit", value: 1 }]);

  return false;
}

function removeFalsePositivePatterns(source: string): string {
  const patterns = [
    IMEI_REGEX, // IMEI has to go before the URI parser otherwise an IMEI with : will be treated as a URI
    URI_REGEX,
    UUID_REGEX,
    INTL_PHONE_NUMBER_REGEX,
    UPS_REGEX,
    COORDINATES_REGEX,
  ];

  let filtered = source;
  patterns.forEach((pattern: RegExp) => {
    filtered = filtered.replaceAll(pattern, "");
  });
  return filtered;
}

function cardFound(filtered: string): boolean {
  let found = false;

  const regexMatches = filtered.match(LAX_NUMBER_REGEX);
  if (!regexMatches) return found;

  regexMatches.forEach(match => {
    if (!match) return;
    const normalized = match.replace(/[.\s-]+/g, ""); // remove whitespace/dashes/dots

    if (normalized.length < 14 || normalized.length > 16) return;
    if (!isValidLuhn(normalized)) return;

    const company = detectCard(normalized);
    if (!company) return;

    found = true;
  });

  return found;
}

function detectCard(value: string): boolean {
  for (const [, detector] of Object.entries(CARD_COMPANY_DETECTORS)) {
    if (detector(value)) {
      return true;
    }
  }

  return false;
}

export function isValidLuhn(number: string): boolean {
  let sum = 0;
  let shouldDouble = false;

  for (let i = number.length - 1; i >= 0; i--) {
    let digit = parseInt(number.charAt(i), 10);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
}
